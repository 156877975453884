import React from "react";
import { Helmet } from "react-helmet";
import { Content } from "../components/body/Body";

import { Footer } from "../components/Footer";
import { TransitionNavigation } from "../components/header/SiteNav";
import { PostFullContent } from "../components/PostContent";
import { Wrapper } from "../components/Wrapper";
import IndexLayout from "../layouts";
import {
  NoImage,
  PostFull,
  PostFullHeader,
  PostFullTitle,
} from "../templates/post";

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>AdNerf Security</title>
    </Helmet>
    <Wrapper>
      <TransitionNavigation mode="normal" />
      <Content>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader
            className="post-full-header"
            style={{ paddingBottom: 0 }}
          >
            <PostFullTitle className="post-full-title">
              AdNerf Security
            </PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                At AdNerf security of our customers data is our number one
                priority. We go to great lengths to prevent logging, storing, or
                saving users data.
              </p>

              <h4>Does AdNerf store my data?</h4>
              <p>
                No, AdNerf does not store any data about our users. AdNerf does
                NOT have a control server, so there's nothing to log your data
                to!
              </p>

              <h4>Security measures within AdNerf</h4>
              <p>
                At AdNerf, we believe in building applications that are
                secure-by-default. Security measures implemented for AdNerf
                installations are:
                <ul>
                  <li>On AdNerf.com all connections are encrypted with TLS.</li>
                </ul>
              </p>
            </div>
          </PostFullContent>
        </article>
      </Content>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
